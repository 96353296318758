/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '@rebass/grid/emotion';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Container from '../../components/Container';
import Hero from '../../components/Hero';
import { H1, H2 } from '../../components/StyledHeaders';
import StandardSection from '../../components/StandardSection';
import css from '@emotion/css';
import Button from '../../components/Button';
import HowWeWork from '../../components/HowWeWork';
import NonStretchedImage from '../../utils/NonStretchedImages';

const HiddenBox = styled(Box)`
  display: block;
  ${(props) => props.smDown && css`@media (max-width: ${props.theme.breakpoints[0]}) {display: none;}`}
`;

const WebDevelopment = (props) => {
	const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "chat.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2560, quality: 100) {
						...GatsbyImageSharpFluid
						presentationWidth
					}
				}
			}
			sideImage: file(relativePath: { eq: "robot.png" }) {
				childImageSharp {
					fluid(maxWidth: 512, quality: 100) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Chatbot Development" />

			<Hero height={[ '40vh', '50vh' ]}>
				<Container>
					<div style={{ maxWidth: 720 }}>
						<H1>Chatbot Development</H1>
					</div>
				</Container>
				<NonStretchedImage {...data.placeholderImage.childImageSharp} />
			</Hero>

			<StandardSection>
				<Container>
					<Flex flexWrap="wrap" mx={[ -2, -3 ]} py={'60px'}>
						<Box width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto">
							<H2>What can chatbots do for your company?</H2>
							<p style={{ fontSize: 16, textAlign: 'justify' }}>
								Bots (or chatbots) are the new sales representative. They can help their customers by
								answering questions on a variety of topics, from where to find good restaurants, book
								movie tickets or how to use their service.
							</p>
							<ul style={{ fontSize: 16, textAlign: 'justify' }}>
								<li>Ease of access</li>
								<li>Boost your sales</li>
								<li>Improve customer participation</li>
							</ul>
						</Box>
						<HiddenBox width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto" />

						<Box width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto">
							<Img
								fluid={data.sideImage.childImageSharp.fluid}
								style={{ width: 320, margin: '0 auto' }}
							/>
						</Box>
						<Box width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto">
							<H2>Use Cases</H2>
							<ul style={{ fontSize: 16, textAlign: 'justify' }}>
								<li>Customer service bots</li>
								<li>Ecommerce bots</li>
								<li>Food bots</li>
								<li>Banking & trading bots</li>
							</ul>
							<p style={{ fontSize: 16, textAlign: 'justify' }}>
								By using artificial intelligence, chat bots can help their clients in the same way as a
								human. Another advantage is that they can work independently on their own platform or as
								part of messaging applications such as Slack and Facebook Messenger, Kik, Telegram,
								WeChat, etc.
							</p>
						</Box>

						<Box width={[ 1 ]} px={[ 1, 3 ]} style={{ textAlign: 'center' }}>
							<Button primary="true" as={Link} to={`${props.uri}#contact-us`}>
								Request more info
							</Button>
						</Box>
					</Flex>
				</Container>

				<HowWeWork uri={props.uri} />
			</StandardSection>
		</Layout>
	);
};

export default WebDevelopment;
